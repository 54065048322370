import { template as template_3e7f99b9d6a94cd898554c031c7544d1 } from "@ember/template-compiler";
const WelcomeHeader = template_3e7f99b9d6a94cd898554c031c7544d1(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
